@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 58.51%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: auto;
}

.text-container a {
  color: rgb(68, 68, 242);
  text-decoration: underline;
}

@keyframes pulsate {
  0% {
    border-color: blue;
    border-width: 3px;
  }

  50% {
    border-color: rgb(169, 169, 173);
    border-width: 3px;
  }

  100% {
    border-color: blue;
    border-width: 3px;
  }
}

textarea {
  margin-top: 50px;
  /* border-color: blue; */
  border-width: 1px;
  font-size: 18px;
}

body textarea.pulsate-border {
  animation: pulsate 1s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}

/* Override for h2 tags */
h2 {
  font-size: 24px;
  /* Example size, adjust as needed */
  font-weight: bold;
  /* This makes the text bold, adjust as needed */
}

/* Optional: defining styles for other heading tags */
h1 {
  font-size: 32px;
  /* Larger than h2 */
  font-weight: bold;
}

h3 {
  font-size: 20px;
  /* Smaller than h2 */
  font-weight: bold;
}

h4 {
  font-size: 18px;
  font-weight: bold;
}

h5 {
  font-size: 16px;
  font-weight: bold;
}

h6 {
  font-size: 14px;
  font-weight: bold;
}